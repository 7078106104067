<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-col
        lg="12"
        class="d-flex align-items-center px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-5 mx-auto"
        >
          <b-card-title
            class="mb-3 font-weight-bold color-text-white"
            title-tag="h1"
            style="font-weight: 700; font-size: 32px; margin-top: 100px;"
          >
            GET REQUEST
          </b-card-title>

          <!-- form -->
          <validation-observer ref="loginForm">
            <!-- #default="{invalid}" -->
            <b-form
              class="auth-login-form mt-2"
            >
              <!-- email -->
              <b-form-group
              >
                <validation-provider
                  #default="{ errors }"
                  name="idRequest"
                  vid="idRequest"
                  rules="required"
                >
                  <b-form-input
                    v-model="idRequest"
                    style="height: 52px;"
                    trim
                    placeholder="Enter id request"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                type="submit"
                variant="primary"
                block
                class="mt-2 font-weight-bold"
                style="font-weight: 700; border-radius: 39px; height: 52px; font-size: 20px;"
                @click.prevent="validationForm"
                :disabled="proccessInfo"
              >
                GET Data
                <b-spinner small class="mb-25" v-if="proccessInfo" />
              </b-button>

                <prism
                    language="javascript"
                    class="rounded mb-0"
                >
                {{ responseRequest }}
                </prism>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BAlert, VBTooltip,
  BImgLazy, BCard, BCardBody, BModal, VBModal, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required, email, password } from '@validations'
// import { required, minLength, password, confirmed, email, } from 'vuelidate/lib/validators'
// import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions, mapState, mapMutations } from 'vuex'
import store from '@/store/index'
import Prism from 'vue-prism-component'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import { firebase, auth, db } from '@/firebase'
import useJwt from '@/auth/jwt/useJwt'
import router from '@/router'

export default {
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    BImgLazy,
    BCard,
    BCardBody,
    BModal,
    BSpinner,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    Prism,
  },
  // mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/logo/logo-seocloud.svg'),
      ghaphicsImg: require('@/assets/images/banner/ghaphics.svg'),
      proccessInfo: false,
      // ghaphics.svg

      // validation rules
      email,
      required,
      password,
      passwordFieldType: 'password',
      // confirmed,

      idRequest: '',
      responseRequest: '',
    }
  },
  metaInfo: {
    meta: [
      { name: 'title', content: 'SEO' },
    ],
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/logo/logo-seocloud.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    validationForm() {
      this.$refs.loginForm.validate().then(success => {
        if(success) {
          this.proccessInfo = true
          this.getData()
        }
      })
    },
    getData() {
        db.collection('requests').doc(this.idRequest).get()
        .then(res => {
            this.responseRequest = []
            if(res.exists) {
                this.responseRequest = res.data().CrawlJSON
                this.proccessInfo = false
            } else {
                this.responseRequest = 'NO DATA'
                this.proccessInfo = false
            }
        })
    }
  },
//   created() {
//   }
}
</script>

<style>
/* .lnkHv:hover {
  color: #f4d6ff !important;
} */
body {
  background: url("https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/stripes-bkg.png?alt=media&token=174016c2-d520-4123-b4b7-bead206719ab") 0% 0% no-repeat padding-box,linear-gradient(115deg, #181e38 47%, #7838ff 180%) !important;
}
.modal .modal-header .close {
  transform: none;
  box-shadow: none;
  border-radius: unset;
  background: none;
}
.modal .modal-header .close:hover {
  transform: translate(2px, 2px);
}
.top-nav-login {
  /* background-color: #7838ff; */
  /* background-image: url(/assets/images/pattern-8d0f59e0f30b8e1261b9e36e843460eb.png); */
  /* background-repeat: repeat; */
  margin-right: 50px;
  border: none;
  height: 55px;
  top: 0px;
  position: absolute;
  width: 100%;
}
.img-logo {
  width: 340px;
  height: 40px;
  position: relative;
  top: 25px;
  left: 20px;
}
.brand-logo-svg{fill: white;height: 38px;}
.account-body {
  position: relative;
  padding: 35px 30px 10px;
  margin-bottom: 1em;
  color: #444;
  background-color: #fff;
  border: 1px solid #ddd;
  -webkit-box-shadow: 1px 1px 1px rgba(0,0,0,.15);
  box-shadow: 1px 1px 1px rgba(0,0,0,.15);
  border-radius: 4px;
  width: 450px
}
</style>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.text-card-register {
  font-size: 15px;
}
</style>
